/* Home
================================================ */

#wrapper.home {
    header#page-header {
        height: 65vh;
    }

    #home-presentation {
        overflow: hidden;
        
        >.uk-flex {
            > div {
                &:first-child {
                    > div {
                        max-width: 644px;

                        > div {
                            padding: $margin-l $margin-xl $margin-l $margin-s;
                            position: relative;

                            > img {
                                position: absolute;
                                top: 0;
                                right: 0;
                                transform: translateX(78%);
                                max-width: 320px;
                            }
                        }
                    }
                }

                &:last-child {
                    padding-top: $margin-m;
                    padding-left: $margin-m;

                    img {
                        width: 100%;
                        height: 500px;
                        object-fit: cover;
                    }

                    > div {
                        max-width: 500px;
                        padding-top: $margin-l;
                        padding-right: $margin-s;
                        padding-left: $margin-m;
                    }
                }
            }
        }
    }

    #home-gamme {
        position: relative;
        z-index: 1;

        &:before {
            content: '';
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            background-color: $marron;
            z-index: 2;
            opacity: .4;
        }

        > div {
            z-index: 3;
            position: relative;
        }

        .list-cuvees {
            .cuvee {
                width: 10%;

                .titre-4 {
                    margin: 15px 0 0;
                }
            }
        }
    }

    #home-rs {
        .posts {
            .post {
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    max-height: 340px;
                }
            }
        }
    }
}
