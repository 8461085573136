/*  Page
================================================ */

#wrapper.gabarit,
#wrapper.default {
    main {
        a {
            color: $orange;

            &:hover {
                text-decoration: underline;
            }
        }

        h2 {
            @extend .titre-2;
        }

        h3 {
            @extend .titre-5;
        }
    }
}

#wrapper.error {
    main {
        text-align: center;
    }
}
