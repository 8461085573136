/*  Cuvees
================================================ */

#wrapper.cuvees {
    section {
        padding: 0;
        min-height: 600px;
        display: flex;
        overflow: hidden;

        > div {
            width: 100%;
        }

        [style^="background-image"] {
            background-size: cover;
            .cuvee-info {
                padding-right: $margin-m;

                h2, h3, h4, h5 {
                    color: $blanc;
                }
            }
        }

        .cuvee-info {
            padding: $margin-xl;

            h2, h3, h4, h5 {
                margin: 0;

                + p {
                    margin-top: 0;
                }
            }

            h3 {
                @extend .titre-2;
            }

            h4 {
                @extend .titre-4;
            }
        }

        &:nth-child(5n+3), &:nth-child(5n+5) {
            > .uk-flex {
                flex-direction: row-reverse;
            }
        }

        &:nth-child(2n+1) {
            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -48%, 0);
                height: calc(100% - 30px);
            }
        }

        &:nth-child(2n) {
            img {
                position: absolute;
                left: 75%;
                bottom: 0;
                transform: translate3d(-50%, 11%, 0);
            }

            .cuvee-info {
                &:before {
                    content: "";
                    display: block;
                    height: 200%;
                    width: 100%;
                    background: radial-gradient(ellipse at center, rgba(0,0,0,.5) 30%, rgba(0,0,0,0) 70%);
                    position: absolute;
                    left: 0;
                    top: 0;
                    transform: translate3d(-25%, -25%, 0);
                    border-radius: 50%;
                }

                > div {
                    position: relative;
                }
            }
        }

        &:nth-child(5n+1) {
            text-align: right;
        }
    }

    footer {
        margin-top: 0;
    }
}
