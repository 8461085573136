/*  Variables
================================================ */

$transition: 0.2s;

$line-height-xs: 1.1;

@mixin button($color, $hover) {
    color: $color;
    border-color: $color;

    &:hover {
        background-color: $color;
        color: $hover;
    }
}

@mixin placeholder {
  ::placeholder { @content; }
  :-ms-input-placeholder { @content; }
  ::-ms-input-placeholder { @content; }
}

@mixin selection {
  ::-moz-selection { @content; }
  ::selection { @content; }
}
