/*  Responsive
================================================ */

@media screen and (max-width: 1199px) {
    body {
        #wrapper {
            &.cuvees {
                section {
                    [style^="background-image"] {
                        background-size: cover;
                        .cuvee-info {
                            padding-right: $margin-m;
                        }
                    }

                    .cuvee-info {
                        padding: $margin-l;
                    }

                    &:nth-child(5n+3), &:nth-child(5n+5) {
                        > .uk-flex {
                            flex-direction: row-reverse;

                            .cuvee-info {
                                padding-left: $margin-xl;
                            }
                        }
                    }

                    &:nth-child(2n+1) {
                        img {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate3d(-50%, -48%, 0);
                            height: calc(100% - 30px);
                        }
                    }

                    &:nth-child(2n) {
                        img {
                            position: absolute;
                            left: 75%;
                            bottom: 0;
                            transform: translate3d(-50%, 11%, 0);
                        }

                        .cuvee-info {
                            &:before {
                                content: "";
                                display: block;
                                height: 200%;
                                width: 100%;
                                background: radial-gradient(ellipse at center, rgba(0,0,0,.5) 30%, rgba(0,0,0,0) 70%);
                                position: absolute;
                                left: 0;
                                top: 0;
                                transform: translate3d(-25%, -25%, 0);
                                border-radius: 50%;
                            }

                            > div {
                                position: relative;
                            }
                        }
                    }

                    &:nth-child(5n+1) {
                        text-align: right;

                        .cuvee-info {
                            padding-right: $margin-xl;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 959px) {
    html {
        font-size: calc(#{$font-size} * .8);
    }
    body {
        #wrapper {
            header#page-header {
                nav {
                    position: initial;

                    #header-logo {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translateX(-50%) translateY(-50%);
                    }
                }
            }
            &.home {
                #home-presentation {
                    overflow: hidden;

                    >.uk-flex {
                        > div {
                            &:first-child {
                                > div {

                                    > div {
                                        padding: 0 $margin-m $margin-l $margin-m;

                                        > img {
                                            position: absolute;
                                            top: inherit;
                                            bottom: $margin-m;
                                            left: $margin-m;
                                            right: inherit;
                                            transform: translateY(100%);
                                        }
                                    }

                                    > img {
                                        margin-right: -($margin-l);
                                    }
                                }
                            }

                            &:last-child {
                                padding-top: 0;
                                padding-left: 0;
                                display: flex;
                                flex-direction: column-reverse;

                                img {
                                    margin-left: -($margin-m);
                                }

                                > div {
                                    padding: $margin-l $margin-m;
                                }
                            }
                        }
                    }
                }

                #home-gamme {
                    .list-cuvees {
                        flex-wrap: wrap;

                        .cuvee {
                            width: inherit;
                            max-width: 30%;
                        }
                    }
                }
            }

            &.domaine {

            }

            &.metier {
                [data-uk-slideshow] {
                    #etapes {
                        .etape {
                            .slide-content {
                                max-width: none;
                                left: 0;
                                padding: 0 $margin-l;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
                #metier-plus1 {
                    > div {
                        .uk-flex {
                            flex-direction: column-reverse;
                            > img {
                                margin-top: -($margin-l);
                                margin-right: -60px;
                            }
                        }
                        > img {
                            margin-top: -100px;
                            margin-left: -30px;
                            margin-right: auto;
                            z-index: 3;
                        }
                    }
                }

                #metier-plus3 {
                    .uk-flex {
                        > div {
                            img {
                                width: inherit;
                            }

                            &:first-child {
                                position: relative;
                                z-index: 1;

                                div {
                                    padding: 0 0 $margin-m 0;
                                }
                                img {
                                    margin-left: -($margin-m);
                                    margin-right: $margin-m;
                                }
                            }

                            &:last-child {
                                display: flex;
                                flex-direction: column-reverse;
                                img {
                                    margin-left: $margin-m;
                                    margin-right: -($margin-m);
                                }
                                div {
                                    padding: $margin-m 0 $margin-m 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 639px) {
    body {
        .titre-header {
            b {
                font-size: 3rem;
            }
        }

        #wrapper {
            &.metier {
                [data-uk-slideshow] {
                    .slide-nav {
                        display: none;
                    }
                }

                #metier-plus1 {
                    > div {
                        .uk-flex {
                            > div {
                                padding: $margin-m $margin-m $margin-l;
                            }

                            > img {
                                margin-top: -($margin-m);
                            }
                        }
                    }
                }
            }
        }


        footer#page-footer {
            margin-top: $margin-m;

            div {
                justify-content: center;
            }
        }
    }
}
