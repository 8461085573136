/* Domaine
================================================ */

#wrapper.domaine {
    #domaine-generations {
        padding-top: 0;

        .generation {

            &[data-uk-parallax] {
                min-height: 600px;
            }

            .generation-text {
                padding: $margin-m;
                max-width: 33.33%;
                position: absolute;
                top: 0;
                transform: translateY(-25%);
                text-align: left;

                b {
                    @extend .titre-5;
                }
            }

            &:nth-child(2n) {
                .generation-text {
                    background-color: $blanc;
                    color: $orange;
                }
            }

            &:nth-child(2n+1) {
                .generation-text {
                    background-color: $orange;
                    color: $blanc;
                }

                img {
                    width: 75%;
                }
            }

            &:nth-child(3n+1) {
                text-align: right;

                .generation-text {
                    right: 0;
                    transform: translateY(-33.33%) translateX(-25%);
                }
            }

            &:nth-child(3n+3) {
                .generation-text {
                    right: 0;
                }
            }
        }
    }

    #domaine-chiffres {
        .chiffres {
            min-height: 600px;
            position: relative;
            margin-top: 20px;

            .chiffre {
                font-size: $font-size-s;
                position: absolute;

                // 19%
                &:nth-child(1) {
                    top: 50%;
                    left: 8vw;
                }

                // 62%
                &:nth-child(2) {
                    top: 10%;
                    left: 30vw;
                }

                // 12%
                &:nth-child(3) {
                    top: 20%;
                    left: 60vw;
                }

                // 1%
                &:nth-child(4) {
                    top: 60%;
                    left: 80vw;
                }

                // 6.5%
                &:nth-child(5) {
                    left: 30vw;
                    top: 70%;
                }
            }
        }

        .uk-flex {
            margin-top: $margin-l;
            overflow: hidden;

            > div {

                img {
                    max-width: none;
                    margin-left: (-$margin-l);
                    margin-right: (-$margin-l);
                    width: calc(100% + #{$margin-l} + #{$margin-l});
                }

                &:first-child {
                    img {
                        position: relative;
                        z-index: 1;
                    }

                    div {
                        padding: $margin-l $margin-xl $margin-l 0;
                    }
                }

                &:last-child {
                    div {
                        padding: $margin-l 0 0 $margin-xl;
                    }
                }
            }
        }
    }

    #domaine-equipe {
        .membres {
            min-height: 815px;
            position: relative;

            h2 {
                position: absolute;
                max-width: calc(360px - (#{$margin-m}*2));
                padding: $margin-s $margin-m;
                transform: translateX(40%);
                left: 50%;
            }

            .membre {
                position: absolute;

                // Roland
                &:nth-of-type(6n+1) {
                    left: 50%;
                    top: 50%;
                    transform: translateX(-60%) translateY(-135%);
                }

                //Jeanne
                &:nth-of-type(6n+2) {
                    left: 50%;
                    bottom: 50%;
                    transform: translateX(40%) translateY(35%);
                }

                //Daniel
                &:nth-of-type(6n+3) {
                    left: 50%;
                    top: 50%;
                    transform: translateY(35%) translateX(-10%);
                    z-index: 2;
                }

                // Marine
                &:nth-of-type(6n+4) {
                    left: 50%;
                    top: 50%;
                    transform: translateX(-110%);
                }

                // Gennaro
                &:nth-of-type(6n+5) {
                    right: 50%;
                    top: 50%;
                    transform: translateX(-40%) translateY(-100%);
                    z-index: 2;
                }

                // Adeline
                &:nth-of-type(6n+6) {
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }

                .membre-info {
                    position: absolute;
                    opacity: 0;
                    height: calc(100% - #{$margin-s} * 2);
                    width: calc(100% - #{$margin-s} * 2);
                    background-color: rgba(119, 53, 53, .75);
                    // z-index: -1;
                    transition: $transition;
                    z-index: 1;
                    color: $blanc;
                    top: 0;
                    left: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    padding: $margin-s;

                    h3 {
                        color: $blanc;
                        margin: 0 0 10px;
                    }

                    p {
                        margin: 0;
                    }
                }

                &:hover {
                    z-index: 9;

                    .membre-info {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
