/* Header
================================================ */

header#page-header {
    height: 40vh;
    min-height: 300px;

    .uk-position-top {
        padding: 15px 30px;
        min-height: $margin-l;

        .uk-navbar-toggle {
            position: absolute;
            left: 0;
            color: $blanc;
            top: 50%;
            transform: translateY(-50%);
            min-height: 0;
            padding: 30px;
            cursor: pointer;
        }

        nav {
            #header-logo {
                img {
                    width: 200px;
                }
            }


            #menu-desktop {
                padding-left: 15px;

                > ul {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    margin: -5px -15px;
                    justify-content: flex-end;

                    > li, ul {
                        margin: 5px 15px;

                        &.current-menu-item {
                            a {
                                color: $orange;
                            }
                        }

                        a {
                            font-weight: bold;
                            color: $blanc;
                            text-transform: uppercase;
                            transition: $transition_fast;

                            &:hover {
                                color: $orange;
                            }
                        }
                    }
                }
            }
        }
    }

    #menu-mobile {
        .uk-offcanvas-bar {
            background-color: $marron;
            padding-left: 0;
            padding-right: 0;

            ul {
                padding: 0;
                margin: 0;

                li {
                    list-style: none;

                    a {
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}
