/* Global
================================================ */

html {
    font-size: 100%;
}

body {
    #wrapper {
        display: flex;
        display: -ms-flexbox;
        flex-direction: column;
        min-height: 100vh;
        color: $noir;
        font-family: $montserrat;
        font-size: $font-size;
        line-height: 1.5;
        position: relative;

        [data-uk-parallax], [style^="background-image"] {
            color: $blanc;
            background-repeat: no-repeat;
            background-position: center;
            background-color: $marron;

            &.section {
                min-height: calc(500px - (#{$margin-l} * 2));
            }

            .button {
                @include button($blanc, $marron);
            }

            .titre-1, .titre-2, .titre-3, .titre-4, .titre-5 {
                color: $blanc;
            }
        }

        main {
            flex-grow: 1;
        }
    }
}

@mixin titre($font-size, $font-family) {
    font-size: $font-size;
    font-family: $font-family;
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
    color: $noir;
}

.titre-header {
    font-size: $font-size-l;
    font-weight: bold;
    color: $blanc;
    text-shadow: 0px 2px 40px #000000, 0px 2px 40px #000000;
    line-height: $line-height-xs;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 0 $margin-s;

    b {
        @include titre($font-size-xl, $pistilli);
        letter-spacing: .5px;
    }
}

.titre-1 {
    @include titre($font-size-l, $pistilli);
    letter-spacing: .5px;
}

.titre-2 {
    @include titre($font-size-m, $bernadette);
    color: $marron;
}

.titre-3 {
    @include titre($font-size-m, $pistilli);
    letter-spacing: .5px;
}

.titre-4 {
    @include titre($font-size-s, $pistilli);
    letter-spacing: .5px;
}

.titre-5 {
    @include titre($font-size-s, $bernadette);
}

strong, b {
    font-weight: bold;
}

a {
    text-decoration: none;
    outline: none;

    &:hover {
        text-decoration: none;
    }
}

img {
    height: auto;
    max-width: 100%;
}

p, ul, blockquote {
    &:last-child {
        margin-bottom: 0;
    }
}

.menu {
    ul.menu-lang {
        display: flex;
        justify-content: center;
        padding: 0;
        list-style: none;

        li {
            margin: 0 2.5px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

form, form.wpcf7-form {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    p {
        margin: 0;
    }

    br {
        display: none;
    }

    .wpcf7-form-control-wrap {
        margin-bottom: $margin-s;
        display: block;
    }

    input[type='submit'] {
        display: block;
    }

    span.ajax-loader {
        display: none;
    }

    div.wpcf7-response-output {
        margin: $margin-s 0 0;
        text-align: center;
        border-width: 1px;

        &.wpcf7-validation-errors {
            border-color: $orange;
        }
    }
}

input, select, textarea {
    width: 100%;
    outline: none;
    padding: $margin-s;
    box-sizing: border-box;
    border: solid 1px #aaa;
    background: none;
    font-size: $font-size;
    font-family: $montserrat;
    display: block;
    max-width: 100%;
    transition: $transition;

    &:focus {
        border-color: $orange;
    }
}

textarea {
    min-height: 102px;
}

.button, input[type='submit'] {
    @include button($marron, $blanc);
    @extend .titre-4;
    padding: $margin-s $margin-m;
    line-height: $line-height-xs;
    border: solid 1px;
    transition: $transition;
    display: inline-block;
    margin-top: $margin-m;
    letter-spacing: 1px;
    cursor: pointer;
    text-align: center;

    &:hover {
        text-decoration: none;
    }

    &.button-large {
        padding-left: $margin-l;
        padding-right: $margin-l;
    }
}

.uk-icon-button {
    background-color: $blanc;

    svg {
        fill: $marron;
    }
}

blockquote {
    font-style: normal;
    padding: $margin-s;
    border: solid 1px $blanc;
    font-size: $font-size;
}

section, .section {
    padding-top: $margin-l;
    padding-bottom: $margin-l;

    > .section:first-child {
        padding-top: 0;
    }

    > .section:last-child {
        padding-bottom: 0;
    }
}


@include placeholder {

}

@include selection {

}
