/*  Page
================================================ */

#wrapper.metier {
    .titre-3 {
        margin-bottom: 0;
    }

    [data-uk-slideshow] {
        position: relative;
        margin-top: $margin-l;

        #etapes {
            .etape {
                &:before {
                    content: "";
                    display: block;
                    height: 100%;
                    width: 100%;
                    background: linear-gradient(25deg, rgba(0,0,0,0.70) 10%,rgba(0,0,0,0) 100%);
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                .slide-content {
                    max-width: 420px;
                    position: absolute;
                    top: 50%;
                    left: 25%;
                }
            }
        }

        .uk-slidenav {
            color: $blanc;
        }

        .slide-nav {
            width: 100%;
            position: absolute;
            top: $margin-m;
            left: 50%;
            transform: translateX(-50%);

            &:before {
                content: '';
                width: 100%;
                height: 1px;
                background-color: $blanc;
                position: absolute;
                top: 50%;
            }

            .uk-container {
                padding-left: 80px;
                padding-right: 80px;

                .uk-slideshow-nav {
                    z-index: 1;
                    position: relative;

                    li {
                        position: relative;
                        padding: 0;
                        cursor: pointer;

                        a {
                            background-color: $blanc;
                        }

                        span {
                            position: absolute;
                            width: auto;
                            height: auto;
                            border-radius: 0;
                            background: none;
                            text-indent: inherit;
                            overflow: inherit;
                            transform: translateX(-50%);
                            left: 50%;
                            color: $blanc;
                        }

                        &.uk-active {
                            a {
                                background-color: $orange;
                            }
                            span {
                                color: $orange;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
    }

    #metier-plus1 {
        overflow: hidden;

        > div {
            margin-top: $margin-l;

            .uk-flex {
                position: relative;
                > img {
                    position: relative;
                    z-index: 2
                }

                > div {
                    background-color: $orange;
                    padding: $margin-l $margin-l  $margin-xl;
                    position: relative;
                    z-index: 0;
                }
            }
            > img {
                margin-top: -200px;
                display: block;
                margin-left: auto;
                margin-right: auto;
                position: relative;
                z-index: 1;
            }
        }
    }

    #metier-plus2 {
        position: relative;

        &:before {
            content: "";
            display: block;
            height: 100%;
            width: 100%;
            background: radial-gradient(ellipse at center, rgba(119, 53, 53, .6) 5%, rgba(0,0,0,0) 95%);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            border-radius: 5%;
        }

        > div {
            position: relative;
            z-index: 1;
        }
    }

    #metier-plus3 {
        overflow: hidden;

        .uk-flex {
            > div {
                img {
                    max-width: none;
                    width: calc(100% + #{$margin-s} + #{$margin-l});
                }

                > div {
                    padding-top: $margin-l;
                }

                &:first-child {
                    position: relative;
                    z-index: 1;

                    div {
                        padding-right: $margin-xl;
                        padding-left: $margin-s;
                        padding-bottom: $margin-l;
                    }
                    img {
                        margin-left: -($margin-s);
                        margin-right: -($margin-l);
                    }
                }

                &:last-child {
                    img {
                        margin-right: -($margin-s);
                        margin-left: -($margin-l);
                    }
                    div {
                        padding-left: $margin-xl;
                        padding-right: $margin-s;
                    }
                }
            }
        }
    }
}
