/*  Variables
================================================ */

$transition_fast: 0.1s;
$transition: 0.2s;

$line-height-xs: 1.1;

$font-size-xl: 3.8rem;
$font-size-l: 2.6rem;
$font-size-m: 1.8rem;
$font-size-s: 1.225rem;
$font-size: 1rem;

$montserrat: 'Montserrat', sans-serif;
$pistilli: 'Pistilli', serif;
$bernadette: 'Bernadette', sans-serif;

$margin-xs: 0.59375rem;
$margin-s: 1.1875rem;
$margin-m: 2.375rem;
$margin-l: 4.75rem;
$margin-xl: 9.5rem;

@mixin placeholder {
  ::placeholder { @content; }
  :-ms-input-placeholder { @content; }
  ::-ms-input-placeholder { @content; }
}

@mixin selection {
  ::-moz-selection { @content; }
  ::selection { @content; }
}
