/*  Fonts
================================================ */

@font-face {
    font-family: 'Pistilli';
    src: url('../fonts/pistilli.eot');
    src: url('../fonts/pistilli.eot?#iefix') format('embedded-opentype'),
         url('../fonts/pistilli.woff2') format('woff2'),
         url('../fonts/pistilli.woff') format('woff'),
         url('../fonts/pistilli.ttf') format('truetype'),
         url('../fonts/pistilli.svg#pistilli') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bernadette';
    src: url('../fonts/bernadette.eot');
    src: url('../fonts/bernadette.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bernadette.woff2') format('woff2'),
         url('../fonts/bernadette.woff') format('woff'),
         url('../fonts/bernadette.ttf') format('truetype'),
         url('../fonts/bernadette.svg#bernadette') format('svg');
    font-weight: normal;
    font-style: normal;
}
