/*  Footer
================================================ */

footer#page-footer {
    margin-top: $margin-l;
    text-align: center;
    background-color: $marron;

    a {
        color: $blanc;
        transition: $transition_fast;

        &:hover {
            color: $orange;
        }
    }

    #footer-top {
        padding-top: $margin-l;
        padding-bottom: $margin-l;

        #footer-menu {
            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    a {
                        font-weight: bold;
                        text-transform: uppercase;
                        transition: $transition;
                    }
                }
            }
        }

        #footer-coordonnees {
            p {
                margin: $margin-xs;

                &:first-child {
                    margin: 0;
                    font-weight: bold;
                    font-size: $font-size-s;
                    line-height: $line-height-xs;
                }
            }

            > div {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        #footer-rs {
            div {
                margin: -5px;

                a {
                    margin: 5px;
                }
            }

        }
    }

    #copyright {
        margin: 0 0 10px;
        padding: 0 15px;

        a {
            text-decoration: underline;
        }
    }
}
